import {
  __objRest,
  __spreadProps,
  __spreadValues,
  styled
} from "./chunk-3SPRXNF4.mjs";

// src/LexicalEditor/index.tsx
import { BeautifulMentionNode as LexicalMentionNode } from "lexical-beautiful-mentions";

// src/LexicalEditor/LexicalEditor.tsx
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { LexicalErrorBoundary } from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { $getRoot } from "lexical";

// src/LexicalEditor/plugins/LexicalOnChange.tsx
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useEffect } from "react";
function OnChangePlugin({
  onChange
}) {
  const [editor] = useLexicalComposerContext();
  useEffect(() => {
    return editor.registerUpdateListener(({ editorState }) => {
      onChange(editorState, editor);
    });
  }, [editor, onChange]);
  return null;
}

// src/LexicalEditor/LexicalEditor.tsx
import { jsx, jsxs } from "react/jsx-runtime";
var LexicalEditorContainer = styled("div", {
  position: "relative"
});
var StyledContentEditable = styled(ContentEditable, {
  minHeight: "$8",
  width: "100%",
  resize: "vertical",
  fontSize: "$1",
  position: "relative",
  tabSize: 1,
  padding: "$3 $2",
  caretColor: "$input_default_caret_enabled",
  border: "solid 1px $input_default_border_enabled",
  borderRadius: "$rounded",
  backgroundColor: "$input_background_enabled"
});
var StyledPlaceholder = styled("div", {
  color: "$label_secondary_text_enabled",
  overflow: "hidden",
  position: "absolute",
  textOverflow: "ellipsis",
  top: "$2",
  left: "$2",
  fontSize: "$1",
  userSelect: "none",
  display: "inline-block",
  pointerEvents: "none"
});
var beautifulMentionsTheme = {
  // Use the trigger name as the key
  $: "mentions-chip"
};
function LexicalEditor({
  children: plugins,
  placeholder,
  initialConfig = {},
  editorCss = {},
  onEditorChange
}) {
  const defaultConfig = __spreadValues({
    namespace: "LexicalEditor",
    theme: {
      beautifulMentions: beautifulMentionsTheme
    },
    onError: (error) => {
      console.error("Lexical Error:", error);
    }
  }, initialConfig);
  function onChange(editorState, editor) {
    const stringifiedEditorState = JSON.stringify(editorState.toJSON());
    const parsedEditorState = editor.parseEditorState(stringifiedEditorState);
    const editorStateTextString = parsedEditorState.read(
      () => $getRoot().getTextContent()
    );
    onEditorChange(editorStateTextString);
  }
  return /* @__PURE__ */ jsxs(LexicalComposer, { initialConfig: defaultConfig, children: [
    /* @__PURE__ */ jsx(LexicalEditorContainer, { children: /* @__PURE__ */ jsx(
      RichTextPlugin,
      {
        contentEditable: /* @__PURE__ */ jsx(
          StyledContentEditable,
          {
            css: __spreadValues({}, editorCss),
            "aria-placeholder": placeholder,
            placeholder: /* @__PURE__ */ jsx(StyledPlaceholder, { children: placeholder })
          }
        ),
        placeholder: /* @__PURE__ */ jsx(StyledPlaceholder, { children: placeholder }),
        ErrorBoundary: LexicalErrorBoundary
      }
    ) }),
    plugins,
    /* @__PURE__ */ jsx(HistoryPlugin, {}),
    /* @__PURE__ */ jsx(AutoFocusPlugin, {}),
    /* @__PURE__ */ jsx(OnChangePlugin, { onChange })
  ] });
}

// src/LexicalEditor/plugins/LexicalDisabled.tsx
import { useLexicalComposerContext as useLexicalComposerContext2 } from "@lexical/react/LexicalComposerContext";
import { useEffect as useEffect2 } from "react";
function LexicalDisabledPlugin({
  isDisabled
}) {
  const [editor] = useLexicalComposerContext2();
  useEffect2(() => {
    editor.setEditable(!isDisabled);
  }, [editor, isDisabled]);
  return null;
}

// src/LexicalEditor/plugins/LexicalInitialState.tsx
import { useLexicalComposerContext as useLexicalComposerContext3 } from "@lexical/react/LexicalComposerContext";
import { $createParagraphNode, $createTextNode, $getRoot as $getRoot2 } from "lexical";
import { $createBeautifulMentionNode } from "lexical-beautiful-mentions";
import { useEffect as useEffect3 } from "react";
var MENTION_REGEX = /\$[\w]+/;
function LexicalInitialStatePlugin({ value }) {
  const [editor] = useLexicalComposerContext3();
  useEffect3(() => {
    editor.update(() => {
      var _a;
      const root = $getRoot2();
      root.clear();
      const paragraphNode = $createParagraphNode();
      const words = value.split(" ");
      for (let i = 0; i < words.length; i++) {
        const word = words[i];
        let formattedWord = word;
        let createNode;
        if (word.length === 0) {
          createNode = $createTextNode;
          formattedWord = "";
        } else if (MENTION_REGEX.test(word)) {
          createNode = (text) => $createBeautifulMentionNode("", text);
        } else {
          createNode = $createTextNode;
        }
        if (createNode) {
          if (i < words.length - 1 && word.length !== 0) {
            formattedWord += " ";
          }
          paragraphNode.append(createNode(formattedWord));
        }
      }
      (_a = paragraphNode.getLastChild()) == null ? void 0 : _a.selectEnd();
      root.append(paragraphNode);
    });
  }, [editor, value.split]);
  return null;
}

// src/LexicalEditor/plugins/LexicalMentions.tsx
import { Popover } from "@radix-ui/react-popover";
import {
  BeautifulMentionsPlugin
} from "lexical-beautiful-mentions";
import { forwardRef } from "react";
import { jsx as jsx2 } from "react/jsx-runtime";
var LexicalMenuItem = styled("li", {
  all: "unset",
  padding: "$2 $4",
  userSelect: "none",
  display: "flex",
  alignItems: "center",
  gap: "$4",
  cursor: "pointer",
  backgroundColor: "#fff",
  "&[aria-selected=true]": {
    backgroundColor: "rgba(80, 94, 113, 0.16)"
  },
  fontSize: "$1"
});
var LexicalMenuContent = styled("ul", {
  overflow: "auto",
  width: "min-content",
  padding: "$1 0px",
  background: "$neutral0",
  filter: "drop-shadow($elevation2)",
  borderRadius: "$rounded"
});
function CustomMenu(_a) {
  var _b = _a, {
    loading,
    menuCss
  } = _b, props = __objRest(_b, [
    "loading",
    "menuCss"
  ]);
  return /* @__PURE__ */ jsx2(Popover, { children: /* @__PURE__ */ jsx2(LexicalMenuContent, __spreadProps(__spreadValues({}, props), { css: menuCss })) });
}
var CustomMenuItem = forwardRef((_a, ref) => {
  var _b = _a, { selected, item } = _b, props = __objRest(_b, ["selected", "item"]);
  return /* @__PURE__ */ jsx2(LexicalMenuItem, __spreadProps(__spreadValues({}, props), { ref }));
});
function LexicalMentionsPlugin({
  mentionItems,
  menuCss,
  mentionItemLimit = 30
}) {
  return /* @__PURE__ */ jsx2(
    BeautifulMentionsPlugin,
    {
      items: mentionItems,
      menuComponent: (props) => /* @__PURE__ */ jsx2(CustomMenu, __spreadProps(__spreadValues({}, props), { menuCss })),
      menuItemComponent: CustomMenuItem,
      menuItemLimit: mentionItemLimit
    }
  );
}

// src/LexicalEditor/plugins/LexicalMentionsTriggers.ts
var LexicalMentionsTriggers = {
  DOLLAR: "\\$",
  AMPERSAND: "&",
  EXCLAMATION: "!",
  AT: "@",
  HASH: "#",
  ASTERISK: "*"
  // Add more triggers as needed
};

// src/LexicalEditor/index.tsx
import { $createParagraphNode as $createParagraphNode2, $createTextNode as $createTextNode2, $getRoot as $getRoot3 } from "lexical";
var LexicalEditor_default = {
  LexicalEditor,
  $getRoot: $getRoot3,
  $createTextNode: $createTextNode2,
  $createParagraphNode: $createParagraphNode2,
  LexicalMentionsTriggers,
  LexicalMentionsPlugin,
  LexicalDisabledPlugin,
  LexicalMentionNode,
  LexicalInitialStatePlugin
};
export {
  LexicalEditor_default as default
};
